import React from "react";

import { Layout, SEO } from '../../../components/structure';
import { Content, Hero } from '../../../components/blocks';
import AccessibilitySectionLinks from "../../../components/blocks/sectionLinks"

const AccessibilityPage = () => (
  <Layout>
    <SEO title="Accessibility" />
    <Hero title={'Integrated Accessibility Standards'} />
    <Content>
      <div style={{width: '90%', maxWidth: '1080px', margin: 'auto', marginBottom: '20px'}}>
        <AccessibilitySectionLinks />
        <div className="inner">
          <h1 style={{textAlign: 'left'}}>Integrated Accessibility Standards</h1>
          <h3 style={{textAlign: 'left'}}>Policy</h3>
          <p style={{textAlign: 'left'}}>
            The following policy has been established by Points to govern the provision of services with Regulation
            191/11, “Integrated Accessibility Standards” (“Regulation”) under the Accessibility for Ontarians with
            Disabilities Act, 2005.
          </p>
          <p style={{textAlign: 'left'}}>
            These standards are developed to break down barriers and increase accessibility for persons with disabilities
            in the areas of information, communications and employment.
          </p>
          <p style={{textAlign: 'left'}}>
            Points is governed by this policy, as well as the Accessibility Standards for Customer Service Policy and the
            Accessibility for Ontarians with Disabilities Act, 2005, in meeting the accessibility needs of persons with
            disabilities. Points will provide our Integrated Accessibility Standards to anyone that requests a copy.
          </p>
          <h2 style={{textAlign: 'left'}}>Process</h2>
          <h3 style={{textAlign: 'left'}}>Commitment</h3>
          <p style={{textAlign: 'left'}}>
            Points is committed to treating all people in a way that allows them to maintain their dignity and
            independence. We believe in integration and equal opportunity. We are committed to meeting the needs of
            persons with disabilities in a timely manner, and will do so by preventing and removing barriers to
            accessibility and meeting accessibility requirements under the Accessibility for Ontarians with Disabilities
            Act. This policy will be implemented in accordance with the time frames established by the Regulation.
          </p>
          <h3 style={{textAlign: 'left'}}>Accessibility Plan</h3>
          <p style={{textAlign: 'left'}}>
            Points will develop, maintain and document an accessibility plan outlining the company’s strategy to prevent
            and remove barriers from its workplace and to improve opportunities for persons with disabilities.
          </p>
          <p style={{textAlign: 'left'}}>
            The accessibility plan will be reviewed and updated at least once every five years, and will be posted on the
            company’s website. Upon request, Points will provide a copy of the accessibility plan in an accessible format.
          </p>
          <h3 style={{textAlign: 'left'}}>Training Employees and Contractors</h3>
          <p style={{textAlign: 'left'}}>
            Points will ensure that training is provided on the requirements of the accessibility standards referred to in
            the Regulation, and continue to provide training on the Human Rights Code as it pertains to persons with
            disabilities, to:
          </p>
          <ul style={{textAlign: 'left'}}>
            <li>all relevant employees and contractors;</li>
            <li>all persons who participate in developing Points’ policies; and</li>
            <li>all other persons who provide goods, services or facilities on behalf of the company.</li>
          </ul>
          <p style={{textAlign: 'left'}}>
            The training will be appropriate to the duties of the employees, contractors and other
            persons. Employees will be trained when changes are made to the accessibility policy. New employees will be
            trained during their new hire orientation.
          </p>
          <p style={{textAlign: 'left'}}>
            Points will keep a record of the training it provides.
          </p>
          <h2 style={{textAlign: 'left'}}>INFORMATION AND COMMUNICATIONS STANDARDS</h2>
          <h3 style={{textAlign: 'left'}}>Feedback</h3>
          <p style={{textAlign: 'left'}}>
            Points will continue to ensure that its process for receiving and responding to feedback is accessible to
            persons with disabilities by providing, or arranging for the provision of, accessible formats and
            communications supports, upon request.
          </p>
          <h3 style={{textAlign: 'left'}}>Accessible Formats and Communication Supports</h3>
          <p style={{textAlign: 'left'}}>
            Upon request, Points will provide, or will arrange for the provision of, accessible formats and communication
            supports for persons with disabilities in a timely manner that takes into account the person’s accessibility
            needs due to disability.
          </p>
          <p style={{textAlign: 'left'}}>
            Points will consult with the person making the request in determining the suitability of an accessible format
            or communication support.
          </p>
          <p style={{textAlign: 'left'}}>
            Points will also notify the public about the availability of accessible formats and communication supports.
          </p>
          <h3 style={{textAlign: 'left'}}>Accessible Websites and Web Content</h3>
          <p style={{textAlign: 'left'}}>
            Points will ensure that our Internet websites, including web content, conform to the World Wide Web Consortium
            Web Content Accessibility Guidelines (WCAG) 2.0, at Level AA except where this is impracticable.
          </p>
          <h2 style={{textAlign: 'left'}}>EMPLOYMENT STANDARDS</h2>
          <h3 style={{textAlign: 'left'}}>Recruitment</h3>
          <p style={{textAlign: 'left'}}>
            Points will notify its employees and the public about the availability of accommodation for applicants with
            disabilities in its recruitment process.
          </p>
          <h3 style={{textAlign: 'left'}}>Recruitment, Assessment or Selection Process</h3>
          <p style={{textAlign: 'left'}}>
            Points will notify job applicants, when they are individually selected to participate further in an assessment
            or selection process, that accommodations are available upon request in relation to the materials or processes
            to be used.
          </p>
          <p style={{textAlign: 'left'}}>
            If a selected applicant requests an accommodation, Points will consult with the applicant and provide, or
            arrange for the provision of, a suitable accommodation in a manner that takes into account the applicant’s
            accessibility needs due to disability.
          </p>
          <h3 style={{textAlign: 'left'}}>Notice to Successful Applicants</h3>
          <p style={{textAlign: 'left'}}>
            When making offers of employment, Points will notify the successful applicant of its policies for
            accommodating employees with disabilities.
          </p>
          <h3 style={{textAlign: 'left'}}>Informing Employees of Supports</h3>
          <p style={{textAlign: 'left'}}>
            Points will continue to inform its employees of its policies (and any updates to those policies) used to
            support employees with disabilities, including policies on the provision of job accommodations that take into
            account an employee’s accessibility needs due to disability. This information will be provided to new
            employees as soon as practicable after commencing employment.
          </p>
          <h3 style={{textAlign: 'left'}}>Accessible Formats and Communication Supports for Employees</h3>
          <p style={{textAlign: 'left'}}>
            Upon the request of an employee with a disability, Points will consult with the employee to provide, or
            arrange for the provision of, accessible formats and communication supports for information that is needed to
            perform his/her job, and information that is generally available to other employees.
          </p>
          <p style={{textAlign: 'left'}}>
            In determining the suitability of an accessible format or communication support, Points will consult with the
            employee making the request.
          </p>
          <h3 style={{textAlign: 'left'}}>Workplace Emergency Response Information</h3>
          <p style={{textAlign: 'left'}}>
            Points will provide individualized workplace emergency response information to employees who have a
            disability, if the disability is such that the individualized information is necessary, and if Points is aware
            of the need for accommodation due to the employee’s disability. Points will provide this information as soon
            as is practicable after becoming aware of the need for accommodation.
          </p>
          <p style={{textAlign: 'left'}}>
            Where the employee requires assistance, Points will, with the consent of the employee, provide the workplace
            emergency response information to the person designated by Points to provide assistance to the employee.
          </p>
          <p style={{textAlign: 'left'}}>
            Points will review the individualized workplace emergency response information when the employee moves to a
            different location in the organization, when the employee’s overall accommodation needs or plans are reviewed.
          </p>
          <h3 style={{textAlign: 'left'}}>Documented Individual Accommodation Plans</h3>
          <p style={{textAlign: 'left'}}>
            Points will maintain a written process for the development of documented individual accommodation plans for
            employees with disabilities.
          </p>
          <p style={{textAlign: 'left'}}>
            If requested, information regarding accessible formats and communications supports provided will also be
            included in individual accommodation plans.
            In addition, the plans will include individualized workplace emergency response information (where required),
            and will identify any other accommodation that is to be provided.
          </p>
          <h3 style={{textAlign: 'left'}}>Return to Work Process</h3>
          <p style={{textAlign: 'left'}}>
            Points maintains a documented return to work process for its employees who have been absent from work due to a
            disability and who require disability-related accommodations in order to return to work.
          </p>
          <p style={{textAlign: 'left'}}>
            The return to work process outlines the steps Points will take to facilitate the return to work and will
            include documented individual accommodation plans as part of the process.
          </p>
          <p style={{textAlign: 'left'}}>
            This return to work process will not replace or override any other return to work process created by or under
            any other statute (i.e. the Workplace Safety Insurance Act, 1997).
          </p>
          <h3 style={{textAlign: 'left'}}>Performance Management, Career Development, Advancement and Redeployment</h3>
          <p style={{textAlign: 'left'}}>
            Points will take into account the accessibility needs of employees with disabilities, as well as individual
            accommodation plans, when conducting performance management, providing career development and advancement to
            employees or when redeploying employees.
          </p>
          <h3 style={{textAlign: 'left'}}>Questions about this Policy</h3>
          <p style={{textAlign: 'left'}}>
            This policy has been developed to break down barriers and increase accessibility for persons with disabilities
            in the areas of information and communications and employment. If anyone has a question about the policy, or
            if the purpose of a policy is not understood, an explanation will be provided by:
          </p>
          <p style={{textAlign: 'left'}}>
            Human Resources Department<br />
            111 Richmond St. W., Suite 700<br />
            Toronto, ON, M5H 2G4<br />
            Canada <br />
            Tel: 416-596-6370<br />
            Fax: 416-596-3267<br />
            Email: accessibility@points.com
          </p>
        </div>
      </div>
    </Content>
  </Layout>
);

export default AccessibilityPage;
