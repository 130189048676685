import { Link } from "gatsby";
import React from "react";

const AccessibilitySectionLinks = () => (
  <ul>
    <li><Link to={'/legal/accessibility/'}>Accessibility</Link></li>
    <li><Link to={'/legal/accessibility/customer-service/'}>Accessible Customer Service</Link></li>
    <li><Link to={'/legal/accessibility/integrated-accessibility-standards/'}>Integrated Accessibility Standards</Link></li>
    <li><a href={'https://points-ebgt.s3.amazonaws.com/points/apex/pdfs/points-integrated-accessibility-standards-multi-year-plan-2021-2026.pdf'}  rel={'noreferrer'}>Multi-Year Plan (PDF)</a></li>
  </ul>
);

export default AccessibilitySectionLinks;
